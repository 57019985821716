<template>
	<div class="app flex-row align-items-center">
		<div class="container">
			<loading
				:active.sync="isLoading"
				loader="spinner"
				color="#20A8D8"
				:is-full-page="false"
			/>

			<b-row class="justify-content-center">
				<b-col md="5">
					<b-card-group>
						<b-card no-body class="p-4">
							<b-card-body>
								<div v-if="isValidCode">
									<b-form @submit.prevent="reset" novalidate>
										<h1>Reset Password</h1>
										<p class="text-muted text-left">
											Username: <b>{{ emailAddress }}</b>
										</p>

										<b-input-group class="mb-3 input-with-validation">
											<b-input-group-prepend>
												<b-input-group-text>
													<i class="icon-lock"></i>
												</b-input-group-text>
											</b-input-group-prepend>
											<input
												type="password"
												name="Password"
												v-model="form.password"
												v-validate="{
													required: true,
													regex: passwordRegex,
												}"
												class="form-control"
												placeholder="Password"
												autocomplete="off"
												ref="password"
											/>
										</b-input-group>
										<span v-if="errors.has('Password')" class="help-block">{{
											passwordErrorMsg
										}}</span>

										<b-input-group class="mb-4 input-with-validation">
											<b-input-group-prepend>
												<b-input-group-text>
													<i class="icon-lock"></i>
												</b-input-group-text>
											</b-input-group-prepend>
											<input
												type="password"
												name="Confirm Password"
												v-model="form.confirmPassword"
												v-validate="'required|confirmed:password'"
												class="form-control"
												placeholder="Confirm Password"
												autocomplete="off"
											/>
										</b-input-group>
										<span
											v-if="errors.has('Confirm Password')"
											class="help-block"
											>{{ errors.first('Confirm Password') }} &nbsp;</span
										>

										<b-row class="input-with-validation">
											<b-col cols="6">
												<b-button type="submit" variant="primary" class="px-4">
													Reset
												</b-button>
											</b-col>
										</b-row>
									</b-form>
								</div>
								<div v-if="isValidCode == false">
									<InvalidCode />
								</div>
							</b-card-body>
						</b-card>
					</b-card-group>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
// Component
import InvalidCode from './InvalidCode';

// API
import userSessionApi from '@/api/userSessionApi';
import userApi from '@/api/userApi';

// Others
import { firebase } from '@/config/firebase';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'ResetPassword',
	components: {
		InvalidCode,
		Loading,
	},
	data() {
		return {
			form: {
				password: '',
				confirmPassword: '',
			},
			emailAddress: '',
			resetCode: this.$route.query.oobCode,
			isValidCode: null,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		passwordRegex() {
			return config.passwordRegex;
		},
		passwordErrorMsg() {
			return config.passwordErrorMsg;
		},
	},
	mounted() {
		this.verifyPasswordResetCode();
	},
	methods: {
		verifyPasswordResetCode() {
			this.isLoading = true;

			if (!this.resetCode) {
				this.isValidCode = false;
				this.isLoading = false;
				return;
			}

			firebase
				.auth()
				.verifyPasswordResetCode(this.resetCode)
				.then((email) => {
					this.emailAddress = email;
					this.isValidCode = true;
					this.isLoading = false;
				})
				.catch((_err) => {
					this.isValidCode = false;
					this.isLoading = false;
				});
		},
		getExpirationDate() {
			// 90 Days before the password expires
			let expDate = new Date();
			expDate.setDate(expDate.getDate() + 90);
			return expDate.getTime();
		},
		async reset() {
			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				return;
			}

			this.isLoading = true;

			const { data } = await userApi.validatePassword(
				this.form.password,
				this.emailAddress
			);

			if (data.isSuccess) {
				firebase
					.auth()
					.confirmPasswordReset(this.resetCode, this.form.password)
					.then(async () => {
						await userSessionApi.resetAttempts(this.emailAddress);

						let expDate = new Date();
						expDate.setDate(expDate.getDate() + 90);

						await userApi.updateUser({
							updatedBy: this.emailAddress,
							emailAddress: this.emailAddress,
							isPasswordExpired: false,
							passwordExpirationDate: this.getExpirationDate(),
						});

						this.$toaster.success(
							'The password has been changed. You may now login your account.'
						);

						this.$router.push('/login');
						this.isLoading = false;
					})
					.catch((_err) => {
						this.$toaster.warning(
							'Error resetting password. Please try again.'
						);
						this.isLoading = false;
					});
			} else {
				this.isLoading = false;
				this.$toaster.warning(data.message);
			}
		},
	},
};
</script>